@tailwind base;
@tailwind components;
@tailwind utilities;
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  font-family: 'Inter', sans-serif;
  font-weight: 300;

  --ion-color-primary: #ff8744;
  --ion-color-primary-rgb: 255, 135, 68;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0773c;
  --ion-color-primary-tint: #ff9357;
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 134, 50, 219;
  --ion-color-secondary-contrast: #18181b;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #d7d8da;
  --ion-color-secondary-tint: #2f2f32;

  --ion-color-tertiary: #3532db;
  --ion-color-tertiary-rgb: 53, 50, 219;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2f2cc1;
  --ion-color-tertiary-tint: #4947df;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #d36412;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 255, 255, 255;
  --ion-color-black-contrast: #d36412;
  --ion-color-black-contrast-rgb: 0, 0, 0;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #000000;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-zinc {
  --ion-color-base: #18181b;
  --ion-color-base-rgb: rgb(24 24 27);
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #2f2f32;
  --ion-color-tint: #2f2f32;
}

::selection {
  background: rgba(255, 166, 0, 0.13);
}

.like {
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  background: transparent;
  position: relative;
  cursor: pointer;
  transition: background-color 0.25s ease;
  overflow: hidden;
}

/* Global Button Hover Style for our like/unlike el */
.like:hover {
  background: transparent;
  transition: background-color 0.25s ease;
}

/* Liked/Unliked states of our like icon */
.liked .like-icon-state {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewbox='0 0 100 100'><path fill='%23e11d48' d='M50,88.87 C76.67,70.46 90,53.9 90,39.17 C90,17.08 63.12,3.84 50,27.63 C38.875,3.85 10,17.08 10,39.17 C10,53.9 23.33,70.46 50,88.87 Z'/></svg>");
}

.unliked .like-icon-state {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewbox='0 0 100 100'><path fill='none' stroke='%23666' stroke-width='5' d='M50,88.87 C76.67,70.46 90,53.9 90,39.17 C90,17.08 63.12,3.84 50,27.63 C38.875,3.85 10,17.08 10,39.17 C10,53.9 23.33,70.46 50,88.87 Z'/></svg>");
}

.like span {
  text-indent: -1000px;
  font-size: 1px;
}

.like-icon {
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  top: 15%;
  right: 15%;
  bottom: 15%;
  left: 15%;
}

.liked .like-icon-state {
  transform: scale(0);
}

.liked .like-icon-state {
  opacity: 0;
  animation: 1.2s like-animation both cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

@keyframes like-animation {
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(1.25);
  }
  32% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.unliked .like-icon-state {
  opacity: 0;
  animation: 1.2s unlike-animation both cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

@keyframes unlike-animation {
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(0.98);
  }
  45% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Pop a small animation of the background on like */
.liked {
  background: transparent;
  animation: 1.2s liked-bg-animation both;
}

@keyframes liked-bg-animation {
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Pop a more discreet animation of the background on unlike */
.unliked {
  animation: 1.2s unliked-bg-animation both;
}

@keyframes unliked-bg-animation {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(0.7);
  }
  30% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Pseudo el for our ring animation */
.like::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.75);
  transform: scale(0);
}

/* Apply animation to the ring el */
.liked::before {
  animation: 1.2s bg-ring-animation both;
}

@keyframes bg-ring-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  10% {
    transform: scale(1.5);
    opacity: 0.25;
  }
  15% {
    transform: scale(2);
    opacity: 0.15;
  }
  45% {
    transform: scale(3.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

.saving::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border: 0.15em solid rgba(100, 100, 100, 0.5);
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  animation: spin-animation 400ms infinite linear;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

ion-toolbar {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}

.pushheader {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}

.input-clear-icon.sc-ion-input-md,
.input-clear-icon.sc-ion-input-ios {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><polygon fill='white' points='405,136.798 375.202,107 256,226.202 136.798,107 107,136.798 226.202,256 107,375.202 136.798,405 256,285.798 375.202,405 405,375.202 285.798,256'/></svg>") !important;
  width: 30px;
  height: 30px;
}
body.dark {
  --ion-color-primary: #ff8744;
  --ion-color-primary-rgb: 255, 135, 68;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0773c;
  --ion-color-primary-tint: #ff9357;

  --ion-color-secondary: #18181b;
  --ion-color-secondary-rgb: 134, 50, 219;
  --ion-color-secondary-contrast: #d4d4d8;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #26262c;
  --ion-color-secondary-tint: #18181b;

  --ion-color-tertiary: #3532db;
  --ion-color-tertiary-rgb: 53, 50, 219;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2f2cc1;
  --ion-color-tertiary-tint: #4947df;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #000000;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/* remove gross bubble effect on ios */
input[type=submit], input[type=button] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-border-radius:0px;
  }

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --ion-background-color: #18181b;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}
